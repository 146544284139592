<template>
    <h6>
        <template v-if="mode === 'add'">Add new mock test</template>
        <template v-if="mode === 'edit'">Update details of {{data.Exam_Name}}</template>
    </h6>
    <div class="row">
        <div class="col-12 col-md-6">
            <table class="table table-borderless commonTable">
                <tr>
                    <td>Exam Name :</td>
                    <td>
                        <input type="text" class="form-control form-control-sm" v-model="form.name">
                    </td>
                </tr>
                <tr>
                    <td>Exam Description :</td>
                    <td>
                        <textarea class="form-control form-control-sm"
                                  rows="3" v-model="form.description"
                                  ></textarea>
                    </td>
                </tr>
                <tr>
                    <td>Max. Marks :</td>
                    <td>
                        <input type="number" class="form-control form-control-sm" v-model="form.maxMarks">
                    </td>
                </tr>
                <tr>
                    <td>Min Marks :</td>
                    <td>
                        <input type="number" class="form-control form-control-sm" v-model="form.minMarks">
                    </td>
                </tr>
                <tr>
                    <td>Time (in minute) :</td>
                    <td>
                        <input type="number" class="form-control form-control-sm" v-model="form.time">
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-12 col-md-6" v-if="mode==='edit'">
            <table class="table table-borderless commonTable">
                <tr>
                    <td>Created on :</td>
                    <td>{{$cmd.formatDate(data.Exam_Created).date.dmy}}</td>
                </tr>
                <tr>
                    <td>Last updated on :</td>
                    <td>{{$cmd.formatDate(data.Exam_Updated).date.dmy}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "mockDetails",
    props:{
        mode:{default: "add"},
        data:{required: false},
    },
    data(){
        return{
            form:{
                name:"",
                description:"",
                maxMarks:"",
                minMarks:"",
                time:"",
            }
        }
    }
}
</script>

<style scoped>

</style>