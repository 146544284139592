<template>
    <page-title title="Mock Test"
                :breadcrumb="breadcrumb"
    />
    <mock-lister/>
    <button class="btn btn-primary btn-sm">
        <i class="fas fa-plus"></i>&nbsp;Add Mock Test
    </button>
</template>

<script>
import PageTitle from "@/components/UI/Theme/PageTitle";
import MockLister from "@/views/admin/mock/mockLister";
export default {
    name: "Index",
    components: {MockLister, PageTitle, },
    computed:{
        breadcrumb(){
            return[
                {title: "Dashboard", to:{name:"adminDashboard"}},
                {title: "Mock Test", to:{name:"MockEdit"}},
            ]
        }
    },
}
</script>

<style scoped>

</style>