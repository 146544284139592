<template>
    <table class="table table-borderless">
        <thead>
        <tr>
            <th v-for="(item , index) in refreshedColumns" :key="index">
            {{item.title}}
            </th>
        </tr>
        </thead>

        <tbody>
        <template v-for="(item, index) in refreshedData" :key="index">
            <tr class="rowHeader">
                <td v-for="column in item.columns" :key="column.key">
                    <template v-if="column.type==='custom'">
                        <slot :name="column.key"
                              :row="item"
                              :column="column"
                              :toggle="(purpose='EXPAND',rowKey=item[primaryKey])=>{
                                  this.toggle(purpose, rowKey)
                              }"
                              :collapse="(rowKey=item[primaryKey])=>{
                                  this.collapse(rowKey)
                              }"
                              :collapseAll="()=>{
                                  this.collapseAll()
                              }"

                        >
                            Hi this is a custom cell
                        </slot>
                    </template>
                    <template v-else>
                        <div :class="[column.align]">
                            {{item[column.key]}}
                        </div>
                    </template>
                </td>
            </tr>
            <tr v-if="expandedRow[item[primaryKey]]" class="rowExpand">
                <td :colspan="item.columns.length">
                    <slot name="expandedRow"
                          :row="item"
                    >
                        <div class="text-danger">
                            <p>No template found</p>
                        </div>
                    </slot>
                </td>
            </tr>
        </template>
        </tbody>

    </table>

</template>

<script>
export default {
    name: "Index",
    props:{
        primaryKey:{required:true},
        columns:{required: true},
        data:{required: true},

        multipleExpand:{required: false, type:Boolean, default:false},
    },
    data(){
        return{
            expandedRow:{}
        }
    },

    computed:{

        // this function will return the array of refreshed column
        refreshedColumns(){
            return [...this.columns].map(el=>{
                if(el.type){
                    if(el.type.toUpperCase() === "EXPAND"){
                        console.log("CUSTOMIZED COLUMN CELL WILL RENDER")
                    }else if(el.type.toUpperCase() === "DATE"){
                        console.log("DATE COLUMN CELL WILL RENDER")
                    }else if(el.type.toUpperCase() === "CURRENCY"){
                        console.log("CURRENCY COLUMN CELL WILL RENDER")
                        el.align = "right"
                    }
                }

                el = this.refactorColumnMeta(el)

                return el
            })
        },

        // this function will return the array of refreshed data
        refreshedData(){
            return [...this.data].map(el=>{

                el.columns = this.refreshedColumns
                //console.log(el)

                return el
            })
        },


    },
    methods:{

        refactorColumnMeta(column){
            let columnMeta = {
                align:"text-left",
            }
            Object.keys(columnMeta).forEach(el=>{
                if(!column[el]){
                    column[el] = columnMeta[el]
                }else{
                    column[el] = column[el].toString().trim() === "" ? columnMeta[el] : column[el]
                }

            })

            return column
        },

        // toggle function will help to toggle row
        /*
        * If purpose id same => Collapse
        * Else
        *   if multiple expand => expand both
        *   else => collapse previous and expand new
        *
        * */
        toggle(purpose, rowKey) {
            if (this.expandedRow[rowKey] && this.expandedRow[rowKey] === purpose)
                this.collapse(rowKey)
            else {
                if (this.multipleExpand === false)
                    this.collapseAll()
                this.expand(purpose, rowKey)
            }
        },
        expand(purpose, rowKey){
            this.expandedRow[rowKey] = purpose
        },
        collapse(rowKey){
            delete this.expandedRow[rowKey]
        },

        collapseAll(){
            this.expandedRow = {}
        }
    }


}
</script>

<style scoped>

</style>