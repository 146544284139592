<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="page-title-box">
                <div class="row">
                    <div class="col">
                        <h4 class="page-title">{{title}}</h4>
                        <ol class="breadcrumb" v-if="breadcrumb">
                            <li class="breadcrumb-item" v-for="(items , index) in breadcrumb" :key="index">
                                <router-link :to="items.to">{{items.title}}</router-link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageTitle",
    props:{
        title:{type: String, required: true},
        breadcrumb: {
            type: Object,
        }
    }
}
</script>

<style scoped>

</style>