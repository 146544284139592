<template>
    <lister primary-key="Exam_Id"
            :columns="column"
            :data="data"
            :multiple-expand="false"

    >

        <template #action="table">
            <button class="btn btn-round" @click="edit(table)" title="Update Mock test details">
                <i class="fas fa-pen"></i>
            </button> &nbsp;

            <button class="btn btn-round" @click="questionPaper(table)" title="Show questions in this mock test">
                <i class="fas fa-list"></i>
            </button>

        </template>

        <template #expandedRow>
            <mock-details v-if="expandedComponent.type === 'edit'"
                          mode="edit"
                          :data="expandedComponent.data.details"
            />
            <template v-if="expandedComponent.type === 'questionPaper'">
                <question-lister/>
                <button class="btn btn-primary btn-sm">
                    <i class="fas fa-plus"></i>&nbsp;Add Question
                </button>
            </template>
        </template>

    </lister>
</template>

<script>
import Lister from"@/components/Lister/Index"
import dummyData from "@/service/populator/mock";
import mockDetails from "@/views/admin/mock/component/form/mockDetails";
import QuestionLister from "@/views/admin/mock/questionLister";
export default {
    name: "mockLister",
    components:{QuestionLister, Lister, mockDetails},
    data(){
        return{
            expandedComponent:{
                type:null,
                data:null
            },
            column:dummyData.mockColumn,
            data:dummyData.mockData,
        }
    },
    methods:{
        edit(table){
            this.expandedComponent.type = "edit"
            this.expandedComponent.data = {
                details:table.row,
            }
            table.toggle()
        },
        questionPaper(table){
            this.expandedComponent.type = "questionPaper"
            table.toggle('question')
        }
    }
}
</script>

<style scoped>

</style>