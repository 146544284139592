

const mockColumn = [
    {key:"action", title:"", type:"custom"},
    {key:"Exam_Id", title:"Id", },
    {key:"Exam_Name", title:"Name", },
    {key:"Exam_Max_Marks", title:"Max Marks", },
    {key:"Exam_Min_Marks", title:"Min Marks", },
    {key:"Exam_Time", title:"Time in Min.", },
]
const mockData = [
    {
        "Exam_Id": 1,
        "Exam_Name": "Testing",
        "Exam_Description": "Testing",
        "Exam_Created": "2021-12-16 10:50:32",
        "Exam_Updated": "2021-12-16 10:50:32",
        "Exam_Max_Marks": 10,
        "Exam_Min_Marks": 10,
        "Exam_Time": 10
    },
    {
        "Exam_Id": 2,
        "Exam_Name": "Testing Postman",
        "Exam_Description": "This is a test creation for postman",
        "Exam_Created": "2021-12-16 14:02:13",
        "Exam_Updated": "2021-12-16 14:02:13",
        "Exam_Max_Marks": 100,
        "Exam_Min_Marks": 0,
        "Exam_Time": 60
    },
    {
        "Exam_Id": 3,
        "Exam_Name": "Testing Postman",
        "Exam_Description": "This is a test creation for postman",
        "Exam_Created": "2021-12-16 14:02:28",
        "Exam_Updated": "2021-12-16 14:02:28",
        "Exam_Max_Marks": 100,
        "Exam_Min_Marks": 0,
        "Exam_Time": 60
    },
    {
        "Exam_Id": 4,
        "Exam_Name": "Testing Postman",
        "Exam_Description": "This is a test creation for postman",
        "Exam_Created": "2021-12-16 14:03:24",
        "Exam_Updated": "2021-12-16 14:03:24",
        "Exam_Max_Marks": 100,
        "Exam_Min_Marks": 0,
        "Exam_Time": 60
    },
    {
        "Exam_Id": 5,
        "Exam_Name": "Testing Postman",
        "Exam_Description": "This is a test creation for postman",
        "Exam_Created": "2021-12-16 14:06:03",
        "Exam_Updated": "2021-12-16 14:06:03",
        "Exam_Max_Marks": 100,
        "Exam_Min_Marks": 0,
        "Exam_Time": 60
    },
    {
        "Exam_Id": 6,
        "Exam_Name": "Testing Postman",
        "Exam_Description": "This is a test creation for postman",
        "Exam_Created": "2021-12-16 14:06:13",
        "Exam_Updated": "2021-12-16 14:06:13",
        "Exam_Max_Marks": 100,
        "Exam_Min_Marks": 0,
        "Exam_Time": 60
    },
    {
        "Exam_Id": 7,
        "Exam_Name": "Testing Postman",
        "Exam_Description": "This is a test creation for postman",
        "Exam_Created": "2021-12-16 14:07:05",
        "Exam_Updated": "2021-12-16 14:07:05",
        "Exam_Max_Marks": 100,
        "Exam_Min_Marks": 0,
        "Exam_Time": 60
    }
]


const questionColumn = [
    {key:"action", title:"", type:"custom"},
    {key:"Question_Number", title:"QNO", },
    {key:"Question", title:"Question", },
    {key:"Correct_Marks", title:"Correct", },
    {key:"Negative_Marks", title:"Negative", },
]
const questionData = [
    {
        "Question_Number":1,
        "Question":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur, doloremque ducimus! Fuga, non ullam? Dolor dolores fugit incidunt nulla possimus, provident quae quidem repellendus repudiandae tempora, totam vitae voluptas voluptatem.",
        "Correct_Marks":"1",
        "Negative_Marks":"-1/4",
    },
    {
        "Question_Number":2,
        "Question":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur, doloremque ducimus! Fuga, non ullam? Dolor dolores fugit incidunt nulla possimus, provident quae quidem repellendus repudiandae tempora, totam vitae voluptas voluptatem.",
        "Correct_Marks":"1",
        "Negative_Marks":"-1/4",
    },
    {
        "Question_Number":3,
        "Question":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur, doloremque ducimus! Fuga, non ullam? Dolor dolores fugit incidunt nulla possimus, provident quae quidem repellendus repudiandae tempora, totam vitae voluptas voluptatem.",
        "Correct_Marks":"1",
        "Negative_Marks":"-1/4",
    },
    {
        "Question_Number":4,
        "Question":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur, doloremque ducimus! Fuga, non ullam? Dolor dolores fugit incidunt nulla possimus, provident quae quidem repellendus repudiandae tempora, totam vitae voluptas voluptatem.",
        "Correct_Marks":"1",
        "Negative_Marks":"-1/4",
    },
    {
        "Question_Number":5,
        "Question":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur, doloremque ducimus! Fuga, non ullam? Dolor dolores fugit incidunt nulla possimus, provident quae quidem repellendus repudiandae tempora, totam vitae voluptas voluptatem.",
        "Correct_Marks":"1",
        "Negative_Marks":"-1/4",
    },
    {
        "Question_Number":6,
        "Question":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur, doloremque ducimus! Fuga, non ullam? Dolor dolores fugit incidunt nulla possimus, provident quae quidem repellendus repudiandae tempora, totam vitae voluptas voluptatem.",
        "Correct_Marks":"1",
        "Negative_Marks":"-1/4",
    },
]

export default {
    mockColumn,
    mockData,

    questionColumn,
    questionData,
}