<template>
    <lister primary-key="Exam_Id"
            :columns="column"
            :data="data"
            :multiple-expand="false"

    >
        <template #action>
            <button class="btn btn-round" title="Edit Question">
                <i class="fas fa-pen"></i>
            </button>
        </template>
    </lister>
</template>

<script>
import Lister from"@/components/Lister/Index"
import dummyData from "@/service/populator/mock";

export default {
    name: "questionLister",
    components:{Lister},
    data(){
        return{
            column:dummyData.questionColumn,
            data:dummyData.questionData,
        }
    }
}
</script>

<style scoped>

</style>